import {Picker} from 'pickers/picker';

class SearchFirmPicker extends Picker {
  get strings() {
    return {
    ...super.strings,
    placeholder: this.el.getAttribute('placeholder') || 'Choose a search firm',
  }}

  get pickerOptions() {
    return {
      ...super.pickerOptions,
      tags: true,
      minimumInputLength: 0,
      maximumInputLength: 255,
    };
  }
}

SearchFirmPicker.watch('.search-firm-picker');
